import {
	createRouter,
	createWebHistory
} from 'vue-router'


const routes = [
	{
		path: '/login',
		name: '登录',
		component: () => import( /* webpackChunkName: "about" */ '@/views/login/login'),
	},
	{
	path: '/',
	name: 'VueLayout',
	component: () => import( /* webpackChunkName: "about" */ '@/components/VueLayout'),
	children: [{
			path: '/',
			name: 'index',
			component: () => import( /* webpackChunkName: "about" */ '@/views/index/index'),
		},
		
		{
			path: '/nearbySchool',
			name: '邻里学堂',
			component: () => import( /* webpackChunkName: "about" */ '@/views/nearbySchool/nearbySchool'),
		},
		{
			path: '/networkSchool',
			name: '人人网校',
			component: () => import( /* webpackChunkName: "about" */ '@/views/networkSchool/networkSchool'),
		},
		{
			path: '/digitalLibrary',
			name: '数字图书馆',
			component: () => import( /* webpackChunkName: "about" */
				'@/views/digitalLibrary/digitalLibrary'),
		},
		{
			path: '/studyMap',
			name: '学习地图',
			component: () => import( /* webpackChunkName: "about" */ '@/views/studyMap/studyMap'),
		},
		{
			path: '/personalCenter',
			name: '个人中心',
			component: () => import( /* webpackChunkName: "about" */ '@/views/personalCenter/mine'),
		},
		{
			path: '/classdetail',
			name: '目录',
			component: () => import( /* webpackChunkName: "about" */ '@/views/nearbySchool/classdetail'),
		},
		{
			path: '/courseLive',
			name: '直播课',
			component: () => import( /* webpackChunkName: "about" */ '@/views/nearbySchool/courseLive'),
		},
		{
			path: '/video',
			name: '视频',
			component: () => import( /* webpackChunkName: "about" */ '@/views/nearbySchool/videoz'),
		},
		{
			path: '/activeDetail',
			name: '活动详情',
			component: () => import( /* webpackChunkName: "about" */ '@/views/active/activeDetail'),
		},

	]
}]

// const router = createRouter({
// 	history: createWebHistory(process.env.BASE_URL),
// 	routes
// })
// export default router
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	// next();
	console.log(to)
	if (to.path == "/login" || to.path == "/waitReview") {
		// 登录或者注册才可以往下进行
		next();
	} else {
		// 获取 token
		const token = localStorage.getItem('token');
		// 是否完善学校信息
		// const isSchoolInfo = localStorage.getItem('isSchoolInfo');
		// token 不存在
		if ((token === null || token === '') && to.path != "/waitReview") {
			next('/login');
		}
		// else if (isSchoolInfo == 2 && to.path != "/schoolInfo") {
		// 	next('/schoolInfo');
		// } 
		else {
			next();
		}
	}
})
export default router
