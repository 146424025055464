import createAxios from '@/utils/axiosConfig'
import qs from "qs";

const instance = createAxios()
instance.defaults.baseURL = process.env.VUE_APP_BASE_URL
instance.headers = {
	"Content-Type": "application/json",
}

// 1登录账号登录
export function loginSms(param) {
	const getData = qs.stringify(param);
	return instance.get("/user/loginSms?" + decodeURI(getData));
}
export function loginPhone(param) {
	const getData = qs.stringify(param);
	return instance.post("/user/loginPhone?" + decodeURI(getData));
}

// 邻里学堂
export function schoolz(param) {
	const getData = qs.stringify(param);
	return instance.get("/courses/obg?" + decodeURI(getData));
}

// 课程信息
export function classtit(param) {
	const getData = qs.stringify(param);
	return instance.get("/courses/getCoursesInfo?" + decodeURI(getData));
}
// 课程简介
export function classjianjie(param) {
	const getData = qs.stringify(param);
	return instance.get("/courses/getCoursesDetail?" + decodeURI(getData));
}
// 课程目录
export function classmulu(param) {
	const getData = qs.stringify(param);
	return instance.get("/chapter/getCoursesIndex?" + decodeURI(getData));
}
// 课程推荐
export function classtui(param) {
	const getData = qs.stringify(param);
	return instance.get("/courses/recommendCourses?" + decodeURI(getData));
}
// 课程讨论
export function classtaolun(param) {
	const getData = qs.stringify(param);
	return instance.get("/courses/getCoursesEva?" + decodeURI(getData));
}
// 编辑讨论
export function detitaolun(param) {
	// const postData = qs.stringify(param);

	return instance.post("/coursesEva/updateCoursesEva", param);



}
// 上次观看
export function shangci(param) {
	const getData = qs.stringify(param);
	return instance.get("/courses/progress?" + decodeURI(getData));


}
// 视频
// export function shangvideo(param) {
// 	const getData = qs.stringify(param);
// 	return instance.get("/courses/getCoursesInfoVideo?" + decodeURI(getData));
// }
export function shangvideo(param) {
	const getData = qs.stringify(param);
	return instance.get("/courses/videoLink?" + decodeURI(getData));
}
export function coursesRanking(param) {
	const getData = qs.stringify(param);
	return instance.get("/subscription/coursesRanking?" + decodeURI(getData));
}
export function getMyCoursesList(param) {
	const getData = qs.stringify(param);
	return instance.get("/courses/getMyCoursesList?" + decodeURI(getData));
}
export function classification(param) {
	const getData = qs.stringify(param);
	return instance.get("/courses/classification?" + decodeURI(getData));
}
export function getCarousel(param) {
	const getData = qs.stringify(param);
	return instance.get("/topResource/getCarousel?" + decodeURI(getData));

}
export function seSpace(param) {
	const getData = qs.stringify(param);
	return instance.get("/space/seSpace?" + decodeURI(getData));
}

export function sizeNum(param) {
	const getData = qs.stringify(param);
	return instance.get("/message/size" + decodeURI(getData));
}
export function search2(param) {
	const getData = qs.stringify(param);
	return instance.get("/homesearch/search2?" + decodeURI(getData));
}

export function getActivityDetail(param) {
	const getData = qs.stringify(param);
	return instance.get("/space/getActivityDetail?" + decodeURI(getData));
}
export function seActivity(param) {
	const getData = qs.stringify(param);
	return instance.get("/space/seActivity?" + decodeURI(getData));
}

export function myAll(param) {
	const getData = qs.stringify(param);
	return instance.get("/courses/myAll?" + decodeURI(getData));
}


export function perInfo(param) {
	const getData = qs.stringify(param);
	return instance.get("/resident/perInfo?" + decodeURI(getData));
}
