import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
// import {
// 	ElTimePicker,
// 	ElPopconfirm,
// 	ElConfigProvider,
// 	ElCol,
// 	ElCarouselItem,
// 	ElCarousel,
// }


export default (app) => {
	app.use(ElementPlus)
	
}
