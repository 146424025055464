<template>
	<!--  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
	<router-view />
</template>
<script>
	export default {
		// created() {
		// 	if (localStorage.getItem('loginInfo') == null) {
		// 		let data = {
		// 			"orgName": null,
		// 			"phone": "15733613910",
		// 			"avatar": "https://rz-sqjy.oss-cn-hangzhou.aliyuncs.com/jz/h5/avator1.jpeg",
		// 			"userId": "hHUGMt1u4zl3",
		// 			"orgId": null,
		// 			"role": 9
		// 		}
		// 		localStorage.setItem('token',
		// 			"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiJyYTJJZHJwcENQNksifQ.bKkEna8EJaadKjcIY_Py_JLadyY9WuO18I6bdO774UE"
		// 		);
		// 		localStorage.setItem("loginInfo", JSON.stringify(data));
		// 		// localStorage.setItem("loginInfo", JSON.stringify(data));
		// 	}

		// },
		methods: {

		}
	}
</script>

<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// text-align: center;
		// color: #2c3e50;
	}

	body {
		margin: 0;
		padding: 0;
		background-color: #F0F2F5;
		scrollbar-width: none;
		// /* firefox */

		-ms-overflow-style: none;


		::-webkit-scrollbar {
			width: 2px;
			height: 2px;
			scrollbar-arrow-color: #898989;

			// width: 0 !important;
			// height: 0;
		}
	}
</style>
