import {
	ElNotification
} from 'element-plus'
import axios from 'axios'
import { router } from "vue";


export default function() {
	let request = axios.create()
	request.defaults.timeout = 10000

	const noAuthorUrlArr = ['/user/loginSms', '/user/loginPhone'];

	//请求拦截
	request.interceptors.request.use(
		function(config) {
			
			const noAuthor = noAuthorUrlArr.includes(config.url.split('?')[0]);
			const token = `${localStorage.getItem("token")}`.replace(/"/g, '')
			// console.log(token)
			if (token && !noAuthor && !config.data) {
				config.headers.token = token;
				console.log("111")
				// this.$router.push('/nearbySchool')
			} else {
				console.log("222")
			}
			if (config.data) {
				config.headers.token = token;
				config.headers.responseType = "blob"
				console.log("333")
			}else {
				console.log("444")
			}
			return config
		},
		function(error) {
			// Do something with request error
			return error
		}
	)


	//响应拦截
	request.interceptors.response.use(
		function(response) {



			return response.data
		},
		function(error) {
			let {
				response
			} = error
			if (response) {
				switch (response.status) {
					case 404:
						break
					case 401:
						ElNotification.error({
							title: '错误',
							message: '请重新登录，认证失败' + response.status,
						})
						break
				}
				console.log("555")
				// this.$router.push('/')
				return error
			} else {
				// 没有返回
				console.log("666")
				if (!window.navigator.onLine) {
					// 断网处理...
					ElNotification.error({
						title: '错误',
						message: '网络已断开'
					})
					return
				}
				ElNotification.error({
					title: '错误',
					message: '请求错误 ~'
				})
				return error
			}
		}
	)
	return request
}
