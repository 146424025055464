import {
	createApp
} from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import {
// 	VueJsonp
// }
// from 'vue-jsonp'

// const app = createApp(App)
// import * as api from './network/request'
import * as api from './network/request'
// import * as echarts from 'echarts'
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}
installElementPlus(app)
app.config.globalProperties.$api = api
// app.config.globalProperties.$echarts = echarts
app.use(store).use(router).mount('#app')
